import * as _datetime2 from "@easepick/datetime";

var _datetime = "default" in _datetime2 ? _datetime2.default : _datetime2;

import * as _basePlugin2 from "@easepick/base-plugin";

var _basePlugin = "default" in _basePlugin2 ? _basePlugin2.default : _basePlugin2;

var exports = {};

/**
* @license
* Package: @easepick/range-plugin
* Version: 1.2.0
* https://easepick.com/
* Copyright 2022 Rinat G.
* 
* Licensed under the terms of GNU General Public License Version 2 or later. (http://www.gnu.org/licenses/gpl.html)
*/
!function (t, e) {
  e(exports, _datetime, _basePlugin);
}(exports, function (t, e, i) {
  "use strict";

  class s extends i.BasePlugin {
    tooltipElement;
    triggerElement;
    binds = {
      setStartDate: this.setStartDate.bind(this),
      setEndDate: this.setEndDate.bind(this),
      setDateRange: this.setDateRange.bind(this),
      getStartDate: this.getStartDate.bind(this),
      getEndDate: this.getEndDate.bind(this),
      onView: this.onView.bind(this),
      onShow: this.onShow.bind(this),
      onMouseEnter: this.onMouseEnter.bind(this),
      onMouseLeave: this.onMouseLeave.bind(this),
      onClickCalendarDay: this.onClickCalendarDay.bind(this),
      onClickApplyButton: this.onClickApplyButton.bind(this),
      parseValues: this.parseValues.bind(this),
      updateValues: this.updateValues.bind(this),
      clear: this.clear.bind(this)
    };
    options = {
      elementEnd: null,
      startDate: null,
      endDate: null,
      repick: !1,
      strict: !0,
      delimiter: " - ",
      tooltip: !0,
      tooltipNumber: t => t,
      locale: {
        zero: "",
        one: "day",
        two: "",
        few: "",
        many: "",
        other: "days"
      },
      documentClick: this.hidePicker.bind(this)
    };

    getName() {
      return "RangePlugin";
    }

    onAttach() {
      this.binds._setStartDate = this.picker.setStartDate, this.binds._setEndDate = this.picker.setEndDate, this.binds._setDateRange = this.picker.setDateRange, this.binds._getStartDate = this.picker.getStartDate, this.binds._getEndDate = this.picker.getEndDate, this.binds._parseValues = this.picker.parseValues, this.binds._updateValues = this.picker.updateValues, this.binds._clear = this.picker.clear, this.binds._onClickCalendarDay = this.picker.onClickCalendarDay, this.binds._onClickApplyButton = this.picker.onClickApplyButton, Object.defineProperties(this.picker, {
        setStartDate: {
          configurable: !0,
          value: this.binds.setStartDate
        },
        setEndDate: {
          configurable: !0,
          value: this.binds.setEndDate
        },
        setDateRange: {
          configurable: !0,
          value: this.binds.setDateRange
        },
        getStartDate: {
          configurable: !0,
          value: this.binds.getStartDate
        },
        getEndDate: {
          configurable: !0,
          value: this.binds.getEndDate
        },
        parseValues: {
          configurable: !0,
          value: this.binds.parseValues
        },
        updateValues: {
          configurable: !0,
          value: this.binds.updateValues
        },
        clear: {
          configurable: !0,
          value: this.binds.clear
        },
        onClickCalendarDay: {
          configurable: !0,
          value: this.binds.onClickCalendarDay
        },
        onClickApplyButton: {
          configurable: !0,
          value: this.binds.onClickApplyButton
        }
      }), this.options.elementEnd && (this.options.elementEnd instanceof HTMLElement || (this.options.elementEnd = this.picker.options.doc.querySelector(this.options.elementEnd)), this.options.elementEnd instanceof HTMLInputElement && (this.options.elementEnd.readOnly = this.picker.options.readonly), "function" == typeof this.picker.options.documentClick && (document.removeEventListener("click", this.picker.options.documentClick, !0), "function" == typeof this.options.documentClick && document.addEventListener("click", this.options.documentClick, !0)), this.options.elementEnd.addEventListener("click", this.picker.show.bind(this.picker))), this.options.repick = this.options.repick && this.options.elementEnd instanceof HTMLElement, this.picker.options.date = null, this.picker.on("view", this.binds.onView), this.picker.on("show", this.binds.onShow), this.picker.on("mouseenter", this.binds.onMouseEnter, !0), this.picker.on("mouseleave", this.binds.onMouseLeave, !0), this.checkIntlPluralLocales();
    }

    onDetach() {
      Object.defineProperties(this.picker, {
        setStartDate: {
          configurable: !0,
          value: this.binds._setStartDate
        },
        setEndDate: {
          configurable: !0,
          value: this.binds._setEndDate
        },
        setDateRange: {
          configurable: !0,
          value: this.binds._setDateRange
        },
        getStartDate: {
          configurable: !0,
          value: this.binds._getStartDate
        },
        getEndDate: {
          configurable: !0,
          value: this.binds._getEndDate
        },
        parseValues: {
          configurable: !0,
          value: this.binds._parseValues
        },
        updateValues: {
          configurable: !0,
          value: this.binds._updateValues
        },
        clear: {
          configurable: !0,
          value: this.binds._clear
        },
        onClickCalendarDay: {
          configurable: !0,
          value: this.binds._onClickCalendarDay
        },
        onClickApplyButton: {
          configurable: !0,
          value: this.binds._onClickApplyButton
        }
      }), this.picker.off("view", this.binds.onView), this.picker.off("show", this.binds.onShow), this.picker.off("mouseenter", this.binds.onMouseEnter, !0), this.picker.off("mouseleave", this.binds.onMouseLeave, !0);
    }

    parseValues() {
      if (this.options.startDate || this.options.endDate) this.options.strict ? this.options.startDate && this.options.endDate ? this.setDateRange(this.options.startDate, this.options.endDate) : (this.options.startDate = null, this.options.endDate = null) : (this.options.startDate && this.setStartDate(this.options.startDate), this.options.endDate && this.setEndDate(this.options.endDate));else if (this.options.elementEnd) this.options.strict ? this.picker.options.element instanceof HTMLInputElement && this.picker.options.element.value.length && this.options.elementEnd instanceof HTMLInputElement && this.options.elementEnd.value.length && this.setDateRange(this.picker.options.element.value, this.options.elementEnd.value) : (this.picker.options.element instanceof HTMLInputElement && this.picker.options.element.value.length && this.setStartDate(this.picker.options.element.value), this.options.elementEnd instanceof HTMLInputElement && this.options.elementEnd.value.length && this.setEndDate(this.options.elementEnd.value));else if (this.picker.options.element instanceof HTMLInputElement && this.picker.options.element.value.length) {
        const [t, e] = this.picker.options.element.value.split(this.options.delimiter);
        this.options.strict ? t && e && this.setDateRange(t, e) : (t && this.setStartDate(t), e && this.setEndDate(e));
      }
    }

    updateValues() {
      const t = this.picker.options.element,
            e = this.options.elementEnd,
            i = this.picker.getStartDate(),
            s = this.picker.getEndDate(),
            n = i instanceof Date ? i.format(this.picker.options.format, this.picker.options.lang) : "",
            a = s instanceof Date ? s.format(this.picker.options.format, this.picker.options.lang) : "";
      if (e) t instanceof HTMLInputElement ? t.value = n : t instanceof HTMLElement && (t.innerText = n), e instanceof HTMLInputElement ? e.value = a : e instanceof HTMLElement && (e.innerText = a);else {
        const e = `${n}${n || a ? this.options.delimiter : ""}${a}`;
        t instanceof HTMLInputElement ? t.value = e : t instanceof HTMLElement && (t.innerText = e);
      }
    }

    clear() {
      this.options.startDate = null, this.options.endDate = null, this.picker.datePicked.length = 0, this.updateValues(), this.picker.renderAll(), this.picker.trigger("clear");
    }

    onShow(t) {
      const {
        target: e
      } = t.detail;
      this.triggerElement = e, this.picker.options.scrollToDate && this.getStartDate() instanceof Date && this.picker.gotoDate(this.getStartDate()), this.initializeRepick();
    }

    onView(t) {
      const {
        view: i,
        target: s
      } = t.detail;

      if ("Main" === i && (this.tooltipElement = document.createElement("span"), this.tooltipElement.className = "range-plugin-tooltip", s.appendChild(this.tooltipElement)), "CalendarDay" === i) {
        const t = new e.DateTime(s.dataset.time),
              i = this.picker.datePicked,
              n = i.length ? this.picker.datePicked[0] : this.getStartDate(),
              a = i.length ? this.picker.datePicked[1] : this.getEndDate();
        n && n.isSame(t, "day") && s.classList.add("start"), n && a && (a.isSame(t, "day") && s.classList.add("end"), t.isBetween(n, a) && s.classList.add("in-range"));
      }

      if ("Footer" === i) {
        const t = 1 === this.picker.datePicked.length && !this.options.strict || 2 === this.picker.datePicked.length;
        s.querySelector(".apply-button").disabled = !t;
      }
    }

    hidePicker(t) {
      let e = t.target,
          i = null;
      e.shadowRoot && (e = t.composedPath()[0], i = e.getRootNode().host), this.picker.isShown() && i !== this.picker.ui.wrapper && e !== this.picker.options.element && e !== this.options.elementEnd && this.picker.hide();
    }

    setStartDate(t) {
      const i = new e.DateTime(t, this.picker.options.format);
      this.options.startDate = i ? i.clone() : null, this.updateValues(), this.picker.renderAll();
    }

    setEndDate(t) {
      const i = new e.DateTime(t, this.picker.options.format);
      this.options.endDate = i ? i.clone() : null, this.updateValues(), this.picker.renderAll();
    }

    setDateRange(t, i) {
      const s = new e.DateTime(t, this.picker.options.format),
            n = new e.DateTime(i, this.picker.options.format);
      this.options.startDate = s ? s.clone() : null, this.options.endDate = n ? n.clone() : null, this.updateValues(), this.picker.renderAll();
    }

    getStartDate() {
      return this.options.startDate instanceof Date ? this.options.startDate.clone() : null;
    }

    getEndDate() {
      return this.options.endDate instanceof Date ? this.options.endDate.clone() : null;
    }

    onMouseEnter(t) {
      const i = t.target;

      if (i instanceof HTMLElement) {
        this.isContainer(i) && this.initializeRepick();
        const t = i.closest(".unit");
        if (!(t instanceof HTMLElement)) return;

        if (this.picker.isCalendarDay(t)) {
          if (1 !== this.picker.datePicked.length) return;
          let i = this.picker.datePicked[0].clone(),
              s = new e.DateTime(t.dataset.time),
              n = !1;

          if (i.isAfter(s, "day")) {
            const t = i.clone();
            i = s.clone(), s = t.clone(), n = !0;
          }

          if ([...this.picker.ui.container.querySelectorAll(".day")].forEach(a => {
            const o = new e.DateTime(a.dataset.time),
                  l = this.picker.Calendar.getCalendarDayView(o);
            o.isBetween(i, s) && l.classList.add("in-range"), o.isSame(this.picker.datePicked[0], "day") && (l.classList.add("start"), l.classList.toggle("flipped", n)), a === t && (l.classList.add("end"), l.classList.toggle("flipped", n)), a.className = l.className;
          }), this.options.tooltip) {
            const e = this.options.tooltipNumber(s.diff(i, "day") + 1);

            if (e > 0) {
              const i = new Intl.PluralRules(this.picker.options.lang).select(e),
                    s = `${e} ${this.options.locale[i]}`;
              this.showTooltip(t, s);
            } else this.hideTooltip();
          }
        }
      }
    }

    onMouseLeave(t) {
      if (this.isContainer(t.target) && this.options.repick) {
        const t = this.getStartDate(),
              e = this.getEndDate();
        t && e && (this.picker.datePicked.length = 0, this.picker.renderAll());
      }
    }

    onClickCalendarDay(t) {
      if (this.picker.isCalendarDay(t)) {
        2 === this.picker.datePicked.length && (this.picker.datePicked.length = 0);
        const i = new e.DateTime(t.dataset.time);

        if (this.picker.datePicked[this.picker.datePicked.length] = i, 2 === this.picker.datePicked.length && this.picker.datePicked[0].isAfter(this.picker.datePicked[1])) {
          const t = this.picker.datePicked[1].clone();
          this.picker.datePicked[1] = this.picker.datePicked[0].clone(), this.picker.datePicked[0] = t.clone();
        }

        1 !== this.picker.datePicked.length && this.picker.options.autoApply || this.picker.trigger("preselect", {
          start: this.picker.datePicked[0] instanceof Date ? this.picker.datePicked[0].clone() : null,
          end: this.picker.datePicked[1] instanceof Date ? this.picker.datePicked[1].clone() : null
        }), 1 === this.picker.datePicked.length && (!this.options.strict && this.picker.options.autoApply && (this.picker.options.element === this.triggerElement && this.setStartDate(this.picker.datePicked[0]), this.options.elementEnd === this.triggerElement && this.setEndDate(this.picker.datePicked[0]), this.picker.trigger("select", {
          start: this.picker.getStartDate(),
          end: this.picker.getEndDate()
        })), this.picker.renderAll()), 2 === this.picker.datePicked.length && (this.picker.options.autoApply ? (this.setDateRange(this.picker.datePicked[0], this.picker.datePicked[1]), this.picker.trigger("select", {
          start: this.picker.getStartDate(),
          end: this.picker.getEndDate()
        }), this.picker.hide()) : (this.hideTooltip(), this.picker.renderAll()));
      }
    }

    onClickApplyButton(t) {
      this.picker.isApplyButton(t) && (1 !== this.picker.datePicked.length || this.options.strict || (this.picker.options.element === this.triggerElement && (this.options.endDate = null, this.setStartDate(this.picker.datePicked[0])), this.options.elementEnd === this.triggerElement && (this.options.startDate = null, this.setEndDate(this.picker.datePicked[0]))), 2 === this.picker.datePicked.length && this.setDateRange(this.picker.datePicked[0], this.picker.datePicked[1]), this.picker.trigger("select", {
        start: this.picker.getStartDate(),
        end: this.picker.getEndDate()
      }), this.picker.hide());
    }

    showTooltip(t, e) {
      this.tooltipElement.style.visibility = "visible", this.tooltipElement.innerHTML = e;
      const i = this.picker.ui.container.getBoundingClientRect(),
            s = this.tooltipElement.getBoundingClientRect(),
            n = t.getBoundingClientRect();
      let a = n.top,
          o = n.left;
      a -= i.top, o -= i.left, a -= s.height, o -= s.width / 2, o += n.width / 2, this.tooltipElement.style.top = `${a}px`, this.tooltipElement.style.left = `${o}px`;
    }

    hideTooltip() {
      this.tooltipElement.style.visibility = "hidden";
    }

    checkIntlPluralLocales() {
      if (!this.options.tooltip) return;
      const t = [...new Set([new Intl.PluralRules(this.picker.options.lang).select(0), new Intl.PluralRules(this.picker.options.lang).select(1), new Intl.PluralRules(this.picker.options.lang).select(2), new Intl.PluralRules(this.picker.options.lang).select(6), new Intl.PluralRules(this.picker.options.lang).select(18)])],
            e = Object.keys(this.options.locale);
      t.every(t => e.includes(t)) || console.warn(`${this.getName()}: provide locales (${t.join(", ")}) for correct tooltip text.`);
    }

    initializeRepick() {
      if (!this.options.repick) return;
      const t = this.getStartDate(),
            e = this.getEndDate();
      e && this.triggerElement === this.picker.options.element && (this.picker.datePicked[0] = e), t && this.triggerElement === this.options.elementEnd && (this.picker.datePicked[0] = t);
    }

    isContainer(t) {
      return t === this.picker.ui.container;
    }

  }

  t.RangePlugin = s, Object.defineProperty(t, "__esModule", {
    value: !0
  });
});
export default exports;